<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.application_id') }} : {{ $route.query.application_id }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="datas.daeList" class="btn_add_new mr-2" @click="pdfExport">
              <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
            </b-button>
            <a href="javascript:void(0)" @click="hasHistory() ? $router.go(-1) : ''" aria-current="page" class="btn btn_add_new mr-2"><i class="ri-arrow-left-line"></i>{{ $t('globalTrans.back') }}</a>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col>
                  <list-report-head :base-url="lrcpanBaseUrl" :uri="'/configuration/report-heading/detail'" :org-id="2">
                    <b>
                      <slot v-if="parseInt($route.query.service_id) === 5">
                        {{ $t('li_step.fertilizer_import_reg') }}
                      </slot>
                      <slot v-else-if="parseInt($route.query.service_id) === 6">
                        {{ $t('li_step.fertilizer_manufac_reg') }}
                      </slot>
                      <slot v-else-if="parseInt($route.query.service_id) === 23">
                        {{ $t('li_step.fertilizer_dis_reg') }}
                      </slot>
                    </b>
                    <br/>
                    {{ $t('globalTrans.application_id') }} : {{$route.query.application_id}}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive" v-if="isLoad">
                  <slot v-if="datas">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                      <b-overlay :show="loading">
                        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                          <slot v-if="datas.daeList">
                            <table class="table" border="1">
                              <tr>
                                <th style="width: 30%;" class="text-left">{{ $t('globalTrans.name') }}</th>
                                <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                                <th style="width: 20%">{{ $t('li_step.there_is') }} / {{ $t('li_step.not_there') }}</th>
                                <th style="width: 25%">{{ $t('globalTrans.remarks') }}</th>
                              </tr>
                              <tr v-for="(dae, index) in JSON.parse(datas.daeList)" :key="index">
                                <td>
                                  <slot v-if="parseInt($route.query.service_id) === 5">
                                    {{ $t('li_step.dae_' + parseInt(dae.id))  }}
                                  </slot>
                                  <slot v-if="parseInt($route.query.service_id) === 6">
                                    {{ $t('li_step.dae_prod_' + parseInt(dae.id))  }}
                                  </slot>
                                  <slot v-if="parseInt($route.query.service_id) === 23">
                                    {{ $t('li_step.dae_dist_' + parseInt(dae.id))  }}
                                  </slot>
                                </td>
                                <td>
                                  <a target="_blank" v-if="getFile(datas[checkFileFunction(dae.file)]) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(datas[checkFileFunction(dae.file)])"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                </td>
                                <td>
                                  <slot v-if="dae.yes_no === 1">
                                    {{ $t('li_step.there_is') }}
                                  </slot>
                                  <slot v-else>
                                    {{ $t('li_step.not_there') }}
                                  </slot>
                                </td>
                                <td>
                                  {{ dae.remarks }}
                                </td>
                              </tr>
                            </table>
                          </slot>
                          <slot v-else>
                            <table class="table" border="1">
                              <tr>
                                <th style="width: 30%;" class="text-center">{{ $t('globalTrans.name') }}</th>
                                <th style="width: 10%">{{ $t('globalTrans.attachment') }}</th>
                                <th style="width: 20%">{{ $t('li_step.there_is') }} / {{ $t('li_step.not_there') }}</th>
                                <th style="width: 25%">{{ $t('globalTrans.remarks') }}</th>
                              </tr>
                              <tr v-for="(dae, index) in daeList" :key="index">
                                <td>
                                  <slot v-if="parseInt($route.query.service_id) === 5">
                                    {{ $t('li_step.dae_' + parseInt(dae.id))  }}
                                  </slot>
                                  <slot v-if="parseInt($route.query.service_id) === 6">
                                    {{ $t('li_step.dae_prod_' + parseInt(dae.id))  }}
                                  </slot>
                                  <slot v-if="parseInt($route.query.service_id) === 23">
                                    {{ $t('li_step.dae_dist_' + parseInt(dae.id))  }}
                                  </slot>
                                </td>
                                <td>
                                  <!-- {{ datas[checkFileFunction(dae.file)] }} -->
                                  <a target="_blank" v-if="getFile(datas[checkFileFunction(dae.file)]) !== false" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + getFile(datas[checkFileFunction(dae.file)])"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                                </td>
                                <td>
                                  <b-form-radio-group
                                    v-model="dae.yes_no"
                                    :options="yesNoList"
                                    :name="'dae_'+ dae.id"
                                  ></b-form-radio-group>
                                </td>
                                <td>
                                  <b-col md="8">
                                    <b-form-input
                                      plain
                                      v-model="dae.remarks"
                                      :placeholder="$t('globalTrans.remarks')"
                                    >
                                    </b-form-input>
                                  </b-col>
                                </td>
                              </tr>
                            </table>
                            <div class="col text-right">
                              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                            </div>
                          </slot>
                        </b-form>
                      </b-overlay>
                    </ValidationObserver>
                  </slot>
                  <div class="panel-body text-center mt-3" v-else>
                    <h6 class="text-danger">{{ $t('globalTrans.no_data_found') }}</h6>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { daeAttachment, daeAttachmentSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import ListReportHead from '@/components/custom/ListReportHeadLrcpn.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      search: {
        service_id: this.$route.query.service_id,
        application_id: this.$route.query.application_id
      },
      datas: {},
      isLoad: false,
      onlinePayment: false,
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      challan_no: '',
      districtList: [],
      upazilaList: [],
      loading: false,
      production: [
        { id: 1, file: 'image_73', yes_no: 1, remarks: null },
        { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
        { id: 3, file: 'environmen_79', yes_no: 0, remarks: null },
        { id: 4, file: 'lab_inform_1997', yes_no: 0, remarks: null },
        { id: 5, file: 'manpower_r_7591', yes_no: 0, remarks: null },
        { id: 6, file: 'descriptio_80', yes_no: 0, remarks: null },
        { id: 7, file: 'the_detail_8033', yes_no: 0, remarks: null },
        { id: 8, file: 'nid_8030', yes_no: 0, remarks: null },
        { id: 9, file: 'uptodate_t_3373', yes_no: 0, remarks: null },
        { id: 10, file: 'vat_regist_5439', yes_no: 0, remarks: null },
        { id: 11, file: 'updated_in_7031', yes_no: 0, remarks: null },
        { id: 12, file: 'updated_bf_2888', yes_no: 0, remarks: null },
        { id: 13, file: 'uptodate_b_3755', yes_no: 0, remarks: null },
        { id: 14, file: 'registrati_7107', yes_no: 0, remarks: null },
        { id: 15, file: 'ministry_n_9860', yes_no: 0, remarks: null },
        { id: 16, file: 'undertakin_5815', yes_no: 0, remarks: null },
        { id: 17, file: 'office_war_9306', yes_no: 0, remarks: null },
        { id: 18, file: 'label__sam_3672', yes_no: 0, remarks: null },
        { id: 19, file: 'photocopy__2112', yes_no: 0, remarks: null },
        { id: 20, file: '_year_perf_8133', yes_no: 0, remarks: null },
        { id: 21, file: 'old_regist_7465', yes_no: 0, remarks: null },
        { id: 22, file: 'others_6605', yes_no: 0, remarks: null }
      ],
      import: [
        { id: 1, file: 'image_73', yes_no: 1, remarks: null },
        { id: 2, file: 'rent_agree_5997', yes_no: 1, remarks: null },
        { id: 3, file: 'nid_79', yes_no: 0, remarks: null },
        { id: 4, file: 'updated_tr_5857', yes_no: 0, remarks: null },
        { id: 5, file: 'tin_certif_1997', yes_no: 0, remarks: null },
        { id: 6, file: 'vat_regist_7591', yes_no: 0, remarks: null },
        { id: 7, file: 'updated_in_80', yes_no: 0, remarks: null },
        { id: 8, file: 'updated_ir_8033', yes_no: 0, remarks: null },
        { id: 9, file: 'updated_bf_3787', yes_no: 0, remarks: null },
        { id: 10, file: 'uptodate_b_2747', yes_no: 0, remarks: null },
        { id: 11, file: 'undertakin_8030', yes_no: 0, remarks: null },
        { id: 12, file: 'address_an_7107', yes_no: 0, remarks: null },
        { id: 13, file: 'photocopy__4458', yes_no: 0, remarks: null },
        { id: 14, file: 'authorizat_7806', yes_no: 0, remarks: null },
        { id: 15, file: 'paclobutra_7492', yes_no: 0, remarks: null },
        { id: 16, file: 'approved_s_9860', yes_no: 0, remarks: null },
        { id: 17, file: 'small_and__8570', yes_no: 0, remarks: null },
        { id: 18, file: '_year_perf_2280', yes_no: 0, remarks: null },
        { id: 19, file: 'old_regist_6668', yes_no: 0, remarks: null },
        { id: 20, file: 'others_9133', yes_no: 0, remarks: null }
      ],
      distribution: [
        { id: 1, file: 'image_73', yes_no: 1, remarks: null },
        { id: 2, file: 'documents__5997', yes_no: 1, remarks: null },
        { id: 3, file: 'agreement__79', yes_no: 0, remarks: null },
        { id: 4, file: 'photocopy__1997', yes_no: 0, remarks: null },
        { id: 5, file: 'national_i_7591', yes_no: 0, remarks: null },
        { id: 6, file: 'attested_c_80', yes_no: 0, remarks: null },
        { id: 7, file: 'uptodate_t_8033', yes_no: 0, remarks: null },
        { id: 8, file: 'vat_regist_3373', yes_no: 0, remarks: null },
        { id: 9, file: 'updated_in_8030', yes_no: 0, remarks: null },
        { id: 10, file: 'updated_bf_7107', yes_no: 0, remarks: null },
        { id: 11, file: 'uptodate_b_9860', yes_no: 0, remarks: null },
        { id: 12, file: 'undertakin_5815', yes_no: 0, remarks: null },
        { id: 13, file: 'office_war_6709', yes_no: 0, remarks: null },
        { id: 14, file: '_copies_of_7219', yes_no: 0, remarks: null },
        { id: 15, file: 'photocopy__7316', yes_no: 0, remarks: null },
        { id: 16, file: '_year_perf_4609', yes_no: 0, remarks: null },
        { id: 17, file: 'old_regist_1199', yes_no: 0, remarks: null },
        { id: 18, file: 'others_4859', yes_no: 0, remarks: null }
      ],
      daeList: []
    }
  },
  computed: {
    yesNoList () {
      return [
        { text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes', value: 1 },
        { text: this.$i18n.locale === 'bn' ? 'নাই' : 'No', value: 0 }
      ]
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.entry') : this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.modify')
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (parseInt(this.$route.query.service_id) === 5) {
      this.daeList = this.import
    }
    if (parseInt(this.$route.query.service_id) === 6) {
      this.daeList = this.production
    }
     if (parseInt(this.$route.query.service_id) === 23) {
      this.daeList = this.distribution
    }
    this.loadData()
  },
  methods: {
    async saveUpdate () {
      this.loading = true
      let result = null
      this.datas.daeList = this.daeList
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, daeAttachmentSave, this.datas)
      this.loading = false
      if (result.success) {
         this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
         this.loadData()
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    checkFileFunction (field) {
      return field
    },
    getFile (value) {
      if (value === '') {
         return false
      }
      if (value) {
        const fileData = JSON.parse(value)
        if (fileData[0]) {
          return fileData[0]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    hasHistory () { return window.history.length > 2 },
    pdfExport () {
      let firstReport = this.$t('li_step.fertilizer_import_reg')
      if (parseInt(this.$route.query.service_id) === 6) {
        firstReport = this.$t('li_step.fertilizer_manufac_reg')
      }
      if (parseInt(this.$route.query.service_id) === 23) {
        firstReport = this.$t('li_step.fertilizer_dis_reg')
      }
      const reportTitle = firstReport + '\n' + this.$t('globalTrans.application_id') + ':' + this.$route.query.application_id
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      return upazilaList.filter(upazila => upazila.district_id === districtId)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      return districtList.filter(district => district.division_id === divisionId)
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, daeAttachment, params).then(response => {
        if (response.success) {
          this.datas = response.data
          this.isLoad = true
          // this.$store.dispatch('setList', this.getRelationalData(response.data))
        } else {
          this.isLoad = true
        }
      })
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    }
  }
}
</script>
