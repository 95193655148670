import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-lic-o'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
          columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hhs', alignment: 'center' }
      ]
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'left' },
          { text: vm.$t('li_step.there_is') + '/' + vm.$t('li_step.not_there'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.remarks'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (JSON.parse(vm.datas.daeList).length) {
        JSON.parse(vm.datas.daeList).filter((dae, index) => {
          let yesNo = vm.$t('li_step.not_there')
          if (dae.yes_no === 1) {
            yesNo = vm.$t('li_step.there_is')
          }
          let title = vm.$t('li_step.dae_' + parseInt(dae.id))
          if (parseInt(vm.search.service_id) === 6) {
            title = vm.$t('li_step.dae_prod_' + parseInt(dae.id))
          }
          if (parseInt(vm.search.service_id) === 23) {
            title = vm.$t('li_step.dae_dist_' + parseInt(dae.id))
          }
          const rowItem = [
            { text: title, style: 'td', alignment: 'left' },
            { text: yesNo, style: 'td', alignment: 'center' },
            { text: dae.remarks, style: 'td', alignment: 'center' }
          ]
          allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRows
        }
      })
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
          th: {
            fontSize: 10,
            bold: true,
            margin: [1, 1, 1, 1]
          },
          td: {
            fontSize: 9,
            margin: [1, 1, 1, 1]
          },
          header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 5, 25, 0]
          },
          hhs: {
            fontSize: 13,
            bold: true,
            margin: [10, 5, 25, 5]
          },
          krishi: {
            margin: [0, 10, 0, 15],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
